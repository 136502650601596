<style>
.input-container {
    position: relative;
    display: inline-block;
}

#priceInput {
    padding-right: 50px;
}

#currencySelect {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    border: none;
    background: white;
    /* color: black; */
}

#currencySelect {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 15px;
    padding-left: 2px;
    border: 1px solid #d8d6de;
    border-radius: 4px;
    /* border-top:    1px solid  #d8d6de;
    border-right:  1px solid #d8d6de;
    border-bottom: 1px solid #d8d6de; */
}
</style>
<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc @ok="submit" size="md">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="grade.id === null">Register Grade</h5>
                <h5 v-if="grade.id !== null">Edit Grade</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{}">
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">
                        <b-row>
                            <b-col cols="12" md="7">
                                <b-form-group label-for="season_id" label="Season">
                                    <validation-provider #default="{ errors }" name="Season" rules="">
                                        <b-form-select id="season_id" name="season_id" size="sm" v-model="grade.season_id"
                                            disabled>
                                            <b-form-select-option v-for="season in seasons" :key="season.id" :value="season.id" v-if="season.isRunning">
                                                {{ season.startYear }}/{{ season.endYear }}
                                            </b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.season_id">{{serverErrors.season_id[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="12" md="5">
                                <b-form-group label-for="type" label="Grade Type">
                                    <validation-provider #default="{ errors }" name="Type" rules="">
                                        <b-form-select
                                            id="type"
                                            name="type"
                                            size="sm"
                                            v-model="grade.type"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option :value="1">Company Grade</b-form-select-option>
                                            <!-- <b-form-select-option :value="2">National Grade</b-form-select-option> -->
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.type">{{serverErrors.type[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                       
                        <b-row>
                            <b-col cols="4">
                                <b-form-group labe-for="name" label="Name">
                                    <validation-provider #default="{ errors }" name="Name" rules="">
                                        <b-form-input id="name" name="name" size="sm" v-model="grade.name" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.name">{{
                                            serverErrors.name[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="8" v-if="grade.type != 2">
                                <b-form-group labe-for="price" label="Price">
                                    <validation-provider #default="{ errors }" name="Price" rules="">
                                        <div class="input-container">
                                        <b-form-input id="price" name="price" size="sm" v-model="grade.price" style="width: 100%"/>
                                        <select id="currencySelect" v-model="grade.currency_id" style="width: 40%;">
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option v-for="currency in currencies" :key="currency.id"
                                                :value="currency.id">
                                                {{ currency.abbreviation }}
                                            </b-form-select-option>
                                            </select>
                                        </div><br>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.price">{{
                                            serverErrors.price[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="5" v-if="grade.type == 2">
                                <b-form-group labe-for="code" label="CODE">
                                    <validation-provider #default="{ errors }" name="CODE" rules="">
                                        <b-form-input id="code" name="code" size="sm" v-model="grade.code" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.code">{{serverErrors.code[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-form-group label-for="variety" label="Varities">
                                    <validation-provider #default="{ errors }" name="variety" rules="">
                                        <b-form-select
                                            id="variety"
                                            name="variety"
                                            size="sm"
                                            v-model="grade.variety"
                                        >
                                            <b-form-select-option :value="null">Select...</b-form-select-option>
                                            <b-form-select-option value="F">Flue Cured</b-form-select-option>
                                            <!-- <b-form-select-option value="S">Sun Cured</b-form-select-option>
                                            <b-form-select-option value="B">Burley</b-form-select-option>
                                            <b-form-select-option value="D">Dark Fired</b-form-select-option> -->
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.variety">{{serverErrors.variety[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <b-form-checkbox :checked="grade.isActive" class="custom-control-success" name="status" switch
                                    v-model="grade.isActive">
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckCircleIcon" />
                                    </span>
                                    <span>Active</span>
                                </b-form-checkbox>
                            </b-col>
                            <b-col>
                                <b-form-checkbox :checked="grade.payable" class="custom-control-success" name="status" switch
                                    v-model="grade.payable">
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckCircleIcon" />
                                    </span>
                                    <span>Is payable</span>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>

                        <!-- <b-card>
                            {{ grade }}
                        </b-card> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-body>
                <b-row>
                    <b-col cols="6" md="2">
                        <label>Season</label>
                        <b-form-select size="sm" v-model="grade.season_id" @change="changeSeason()" disabled>
                            <b-form-select-option v-for="season in seasons" :key="season.id" :value="season.id"
                                v-if="season.isRunning">
                                {{ season.startYear }}/{{ season.endYear }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="sm" />
                            <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                                <span class="text-nowrap">Register Grade</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                :busy="isBusy"
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive 
                :fields="columns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #cell(isActive)="data">
                    <b-badge pill :variant="`light-${resolveStatusVariant(data.item.isActive)}`" class="text-capitalize">
                        <span v-if="!data.item.isActive">Blocked</span>
                        <span v-else>Active</span>
                    </b-badge>
                </template>

                <template #cell(prices)="data">
                    <span v-if="data.item.prices.length > 0">{{ formatPrice(data.item.prices[data.item.prices.length - 1].value) }}</span>
                </template>

                <template #cell(currency)="data">
                    <span v-if="data.item.prices.length > 0">{{ data.item.prices[data.item.prices.length - 1].currency.abbreviation }}</span>
                </template>

                <template #cell(variety)="data">
                    <span v-if="data.item.variety == 'F'">FCV</span>
                    <!-- <span v-if="data.item.variety == 'B'">Burley</span>
                    <span v-if="data.item.variety == 'D'">DFC</span>
                    <span v-if="data.item.variety == 'S'">SC</span> -->
                </template>

                <template #cell(type)="data">
                    <span v-if="data.item.type == 1">Company</span>
                    <span v-if="data.item.type == 2">National</span>
                </template>

                <template #cell(payable)="data">
                    <span v-if="data.item.payable == true">Yes</span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="remove(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
    BSpinner, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import gradesStoreModule from '@/views/cromis/grades/crop_grades/gradesStoreModule'
import useGradesList from '@/views/cromis/grades/crop_grades/useGradesList'

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem, BFormDatepicker, BFormCheckbox,
        BSpinner, BBadge,
        ValidationObserver, ValidationProvider,
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const saving = ref(false)
        const currencies = ref([])
        const serverErrors = ref(null)
        const seasons = ref([])

        const grade = ref({
            id: null,
            season_id: null,
            name: null,
            code: null,
            price: null,
            forGreen: false,
            type: null,
            isActive: true,
            payable:true,
            variety:null
        })

        const CROMIS_STORE_MODULE_NAME = 'cromis-grade'

        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, gradesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            await store.dispatch('cromis-grade/seasons', { isRunning: 1 })
                .then(response => {
                    seasons.value = response.data.seasons
                    if (seasons.value.length > 0) {
                        grade.value.season_id = seasons.value[0].id
                        changeSeason(grade.value.season_id)
                    }
                })
                .catch(error => {
                    console.log('got error', error)
                })
                await store.dispatch('cromis-grade/currencies')
                .then(response => {
                    currencies.value = response.data.currencies

                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const changeSeason = async () => {
            season_id.value = grade.value.season_id
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            isBusy,

            // Filers
            season_id,

            // Resolvers
            resolveStatusVariant,
        } = useGradesList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null
            console.log(item)
            let price = 0;
            if(item.prices.length > 0){
                price = item.prices[item.prices.length - 1].value
            }

            grade.value = {
                id: item.id,
                season_id: grade.value.season_id,
                name: item.name,
                code: item.code,
                variety: item.variety,
                price: price,
                currency_id: item.currency.id,
                forGreen: true,
                payable: item.payable,
                type: item.type,
                isActive: !!item.isActive,
            }

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            serverErrors.value = null

            grade.value = {
                id: null,
                season_id: grade.value.season_id,
                name: null,
                code: null,
                price: null,
                currency_id: null,
                forGreen: true,
                payable: true,
                type: null,
                variety: null,
                isActive: true,
            }

            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const formatPrice = (value) => {
            let val = (value / 1).toFixed(0).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (grade.value.id === null || grade.value.id === 0)
                handleCreate()
            else
                handleUpdate(grade.value)
        }

        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            console.log(grade.value)

            await store.dispatch('cromis-grade/create', grade.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Grade ${response.data.name} registered successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-grade/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to grade # ${response.data.name} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-grade/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            seasons,
            currencies,
            grade,

            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            isBusy,

            // Methods
            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            changeSeason,
            invokeCreateForm,
            invokeUpdateForm,
            submit,
            formatPrice,
            isFormValid,
            handleCreate,
            handleUpdate,
            remove,
            resolveStatusVariant,
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '@core/scss/vue/libs/vue-select.scss';
</style>